import React from "react";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getRoleList } from "../../api/admin/AdminUserApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import UserFilterForm from "../../components/users-core/UserFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const userRolesResponseStream = getRoleList();

    return propsStream
      .combineLatest(userRolesResponseStream, (props, userRoles) => ({
        ...props,
        userRoles,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "600px",
      minHeight: "auto",
    },
  }),
);

AdminUserFilterWrapper.propTypes = {
  classes: PropTypes.object,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func,
  userRoles: PropTypes.array,
};

function AdminUserFilterWrapper(props) {
  const { classes } = props;

  return (
    <ModalPaper
      open={props.open}
      title={props.getLocalisationMessage("user_filter", "User Filter")}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
    >
      <UserFilterForm
        filter={props.filter}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
        userRoles={props.userRoles}
        /* getCachedSupplier={getCachedSupplier}
        getSupplierPredictions={getSupplierPredictions} */
      />
    </ModalPaper>
  );
}

export default enhancer(AdminUserFilterWrapper);
