import React from "react";
import PropTypes from "prop-types";
import DataListFilter from "../../helpers/DataListFilter";
import { getRolePredictions } from "../../api/admin/AdminUserApi";
import FormAbstractCodeChips from "./FormAbstractCodeChips";

const baseFilter = new DataListFilter({ page: 0, size: 20 });

FormRoleAbstractChips.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  countryId: PropTypes.number.isRequired,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
};

FormRoleAbstractChips.defaultProps = {};

function FormRoleAbstractChips({ ...props }) {
  return (
    <FormAbstractCodeChips
      {...props}
      getPredictions={searchText =>
        getRolePredictions(baseFilter.setSearch(searchText))
      }
    />
  );
}

export default FormRoleAbstractChips;
